.Slide {
  flex-shrink: 0;
  height: 100%;
  max-height: 380px;
  overflow: hidden;
}

.SlideContainer {
  width: 90%;
}

@media screen and (min-width: 576px) {
  .SlideContainer {
    width: auto;
  }

  .Slide {
    height: auto;
    margin-right: auto;
    max-height: 100%;
    overflow: auto;
  }
}
