.ProjectLogo {
  max-width: 100px;
  height: auto;
}

.DropdownItemContainer:nth-child(odd) {
  border-right: 1px solid var(--bs-dropdown-divider-bg);
  padding-right: 0;
}

.DropdownItemContainer:nth-child(even) {
  padding-left: 0;
}

.Instagram .gradient {
  background: #999;
  border-radius: 50%;
}

.Instagram:hover .gradient {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  border-radius: 50%;
}

.Instagram:active .gradient {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #c97d2a 0%, #bf5732 25%, #b52137 50%, #a61c53 75%, #96146d 100%);
  background: -webkit-linear-gradient(45deg, #c97d2a 0%, #bf5732 25%, #b52137 50%, #a61c53 75%, #96146d 100%);
  background: linear-gradient(45deg, #c97d2a 0%, #bf5732 25%, #b52137 50%, #a61c53 75%, #96146d 100%);
  border-radius: 50%;
}

.SocialMediaIcon {
  color: #999;
  transition-duration: .2s;
  transition-property: color, background;
  transition-timing-function: cubic-bezier(0.33, 0.71, 0.82, 1.06)
}

.SocialMediaIcon:hover,
.SocialMediaIcon:active {
  border-radius: 50%;
  width: 48px;
}

.SocialMediaIcon:hover {
  box-shadow: 0 -2px 10px 4px rgba(0, 0, 0, .25);
  transition-property: box-shadow;
  transition-duration: .5s;
}

.SocialMediaIcon:active {
  box-shadow: 0 -2px 4px 3px rgba(0, 0, 0, .25);
  transition-property: box-shadow;
  transition-duration: .2s;
}

.Facebook:hover {
  color: #203f7d;
}

.Facebook:active {
  color: #182f5e;
}

.YouTube:hover {
  color: #ff0000
}

.YouTube:active {
  color: #cc0000
}
